import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ONEID_ROUTES } from "components/shared/routes"

import Layout from "../components/shared/layout"
import SEO from "../components/shared/seo"
import { Button } from "components/shared/atoms/Button"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "404" }, extension: { eq: "svg" }) {
        name
        publicURL
      }
    }
  `)
  return (
    <Layout>
      <SEO title="404: Introuvable" />
      <Container>
        <div className="illus">
          <img
            src={data.file.publicURL}
            alt="404 illustration"
            width="100%"
            height="auto"
          />
        </div>
        <div className="message">
          <Header>
            <h2 className="main">404.</h2>
            <h3 className="secondary">Oups... Il n'y a rien ici.</h3>
          </Header>

          <Button link={ONEID_ROUTES.HOME} themeStyle="primary">
            Retour
          </Button>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ${props => props.theme.margins.m24} 0 0 0;
  flex-direction: column-reverse;

  @media (min-width: ${props => props.theme.screens.md}) {
    flex-direction: inherit;
  }

  .message {
    flex-shrink: 0;
    padding: 0 ${props => props.theme.paddings.p8};
    margin-bottom: ${props => props.theme.margins.m8};
    text-align: center;

    @media (min-width: ${props => props.theme.screens.md}) {
      text-align: inherit;
      margin: inherit;
    }
  }

  .illus {
    max-width: 40rem;
    flex-shrink: 1;
    margin: 0 auto;
    width: 90%;

    @media (min-width: ${props => props.theme.screens.md}) {
      margin: 0 ${props => props.theme.margins.m20};
      width: 90%;
    }
  }
`

const Header = styled.header`
  margin-bottom: ${props => props.theme.margins.m8};

  .main {
    font-size: ${props => props.theme.fontSizes.xl5};
    font-weight: 600;
    line-height: 1;
    user-select: none;
    color: ${props => props.theme.colors.secondarydark};
  }

  .secondary {
    font-size: ${props => props.theme.fontSizes.xl2};
    font-weight: 300;
    line-height: 1.5;
    user-select: none;
    color: ${props => props.theme.colors.secondarydark};
  }
`
